import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import "../styling/navigation-header.css";
import Mobile from "../components/mobile-nav-header";
import GoogleCSE from "./GoogleCSE";
import { FaChevronDown } from "react-icons/fa";

const NavigationHeader = () => {
  const [activeLink, setActiveLink] = useState(0);
  const [activeSubLink, setActiveSubLink] = useState(null);
  const [mobileVisible, setMobileVisible] = useState(false);

  const toggleMobile = () => {
    setMobileVisible(!mobileVisible);
  };

  const handleMainLinkClick = (index) => {
    setActiveLink(index);
    setActiveSubLink(null);
  };

  const handleSubLinkClick = (index) => {
    setActiveSubLink(index);
  };

  useEffect(() => {
    const pathname = window.location.pathname;

    switch (pathname) {
      case "/about/":
        setActiveLink(1);
        break;
      case "/initiatives/":
        setActiveLink(2);
        break;
      case "/products/":
        setActiveLink(3);
        break;
      case "/product-yarn/":
        setActiveSubLink(4);
        setActiveLink(3);
        break;
      case "/products-filament/":
        setActiveSubLink(5);
        setActiveLink(3);
        break;
      case "/pet-recycling/":
        setActiveSubLink(6);
        setActiveLink(3);
        break;
      case "/faq-page/":
        setActiveLink(7);
        break;
      case "/contact-us/":
        setActiveLink(8);
        break;
      default:
        setActiveLink(0);
    }
  }, []);

  return (
    <div className="navigation-first-div">
      <div className="navigation-header">
        <Link to="/" aria-label="Home">
          <img
            src="/images/home-page/long-logo-1.webp"
            alt="logo loading..."
            className="logo"
          />
        </Link>
        <div className="nav-links">
          <div className="parent-container">
            <div className="seach-bar">
              <GoogleCSE />
            </div>
          </div>

          <Link
            to="/about/"
            className={`nav-link ${activeLink === 1 ? "active" : ""}`}
            onClick={() => handleMainLinkClick(1)}
          >
            ABOUT
          </Link>
          <Link
            to="/initiatives/"
            className={`nav-link ${activeLink === 2 ? "active" : ""}`}
            onClick={() => handleMainLinkClick(2)}
          >
            INITIATIVES
          </Link>
          <div className="dropdown">
            <div className="dropbtn">
              <Link
                to="/products/"
                className={`nav-link ${activeLink === 3 ? "active" : ""}`}
                onClick={() => handleMainLinkClick(3)}
              >
                PRODUCTS
                <FaChevronDown
                  style={{
                    marginLeft: "10px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                />
              </Link>
            </div>
            <div
              className={`dropdown-content ${activeLink === 3 ? "active" : ""}`}
            >
              <p></p>
              <div className="drop-line" />
              <Link
                to="/product-yarn/"
                className={`nav-link ${activeSubLink === 4 ? "active" : ""}`}
                onClick={() => {
                  handleSubLinkClick(4);
                  handleMainLinkClick(3);
                }}
              >
                YARN
              </Link>
              <div className="drop-line" />
              <Link
                to="/products-filament/"
                className={`nav-link ${activeSubLink === 5 ? "active" : ""}`}
                onClick={() => {
                  handleSubLinkClick(5);
                  handleMainLinkClick(3);
                }}
              >
                MONOFILAMENTS
              </Link>
              <div className="drop-line" />
              <Link
                to="/pet-recycling/"
                className={`nav-link ${activeSubLink === 6 ? "active" : ""}`}
                onClick={() => {
                  handleSubLinkClick(6);
                  handleMainLinkClick(3);
                }}
              >
                PET RECYCLING
              </Link>
            </div>
          </div>
          {/* <Link
            to="/faq-page/"
            className={`nav-link ${activeLink === 7 ? "active" : ""}`}
            onClick={() => handleMainLinkClick(7)}
          >
            FAQ
          </Link> */}
          <Link
            to="/contact-us/"
            className={`nav-link ${activeLink === 8 ? "active" : ""}`}
            onClick={() => handleMainLinkClick(8)}
          >
            CONTACT
          </Link>
        </div>

        <button
          type="button"
          className="menu-icon"
          onClick={toggleMobile}
          aria-label="Toggle mobile menu"
        >
          {mobileVisible ? (
            <FontAwesomeIcon
              className="menu-icon-fontAwesomeIcon-first"
              icon={faTimes}
            />
          ) : (
            <FontAwesomeIcon
              className="menu-icon-fontAwesomeIcon-second"
              icon={faBars}
            />
          )}
        </button>

        {mobileVisible && (
          <div className="menu-icon2">
            <Mobile />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationHeader;
