import React, { useEffect } from "react";
import NavBar from "../components/navigation-header";
import "../styling/pages.css";
import { Helmet } from "react-helmet";
//import GoogleCSE from "../components/GoogleCSE";
import BadImg from "../images/new-bad.png";

export default function Page() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#f5f5f5", height: "100vh" }}>
      <Helmet>
        <title>Page not found - Eco Spindles</title>
        <meta name="title" content="Page not found - Eco Spindles" />
        <meta name="description" content="Page not found - Eco Spindles" />
        <link rel="canonical" href="https://www.ecospindles.com/404/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta name="keywords" content="Page not found - Eco Spindles" />
        <meta
          property="og:image"
          content="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/long-logo-1.png"
        />
        <meta property="og:title" content="Page not found - Eco Spindles" />
        <meta
          property="og:description"
          content="Page not found - Eco Spindles"
        />
        <meta property="og:url" content="https://www.ecospindles.com/404/" />
        <meta property="og:site_name" content="Eco Spindles" />
        <link
          rel="preload"
          href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/long-logo-1.png"
          as="image"
        />
        {/* <script
          async
          src="https://cse.google.com/cse.js?cx=d09ac2e3685724410"
        ></script> */}
      </Helmet>

      <NavBar />

      <div style={{ backgroundColor: "#f5f5f5" }}>
        <div>
          <br />
          <center>
            <div className="bad-page">
              <div>
                <img src={BadImg} alt="Logo" className="bad-ig" />
              </div>
              <h1 className="bad-page-header">
                Oh no! This page got all tangled up!
                <br />
                The page you are looking for cannot be found.
              </h1>
              <p className="bad-page-mg">
                Please visit our home page or sitemap to find the information
                you are looking for.
              </p>

              {/* <div style={{ width: "250px" }}></div> */}
              {/* Search Bar */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <a
                  href="/"
                  style={{
                    color: "#fff",
                    backgroundColor: "#313893",
                    padding: "10px 20px",
                    textDecoration: "none",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                    width: "150px",
                    textAlign: "center",
                    fontFamily: '"GT America Trial Cn Lt", sans-serif',
                    fontSize: "20px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#1c5258";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#313893";
                  }}
                >
                  Home Page
                </a>
                <a
                  href="/sitemap/"
                  style={{
                    color: "#fff",
                    backgroundColor: "#313893",
                    padding: "10px 20px",
                    textDecoration: "none",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                    width: "150px",
                    textAlign: "center",
                    fontFamily: '"GT America Trial Cn Lt", sans-serif',
                    fontSize: "20px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#1c5258";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#313893";
                  }}
                >
                  Sitemap
                </a>
                <br />
                <br />
              </div>
              <br />
              <br />
            </div>
          </center>
          <br />
        </div>

        <div className="bad-horizontal-line"></div>

        <center>
          <p className="bad-footer">
            Copyright © 2024 Eco Spindles | Web Design by{" "}
            <a
              aria-label="3cs"
              href="https://www.3cs.lk/"
              target="_blank"
              rel="noreferrer"
              className="web-3cs-link"
            >
              3CS
            </a>
          </p>
        </center>
        <br />
      </div>
    </div>
  );
}
