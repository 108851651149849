import React, { useEffect } from "react";

const GoogleCSE = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cse.google.com/cse.js?cx=d09ac2e3685724410";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //   useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       const wrapper = document.querySelector(".gsc-results-wrapper-overlay");
  //       const buttonBackground = document.querySelector(".gsc-control-cse");
  //       const searchButton = document.querySelector(".gsc-search-button-v2");
  //       const searchBar = document.querySelector(".gsc-input-box");

  //       if (wrapper && buttonBackground && searchButton && searchBar) {
  //         wrapper.style.height = "100vh";
  //         buttonBackground.style.backgroundColor = "transparent";
  //         buttonBackground.style.border = "transparent";
  //         buttonBackground.style.width = "250px";
  //         buttonBackground.style.position = "relative";
  //         searchBar.style.borderRadius = "15px";
  //         buttonBackground.style.borderRadius = "5px";
  //         searchButton.style.padding = "4px 5px";
  //         searchButton.style.position = "absolute";
  //         searchButton.style.top = "30%";
  //         searchButton.style.right = "15%";
  //         searchButton.style.border = "none";
  //         searchButton.style.backgroundColor = "#ffff";
  //         searchButton.style.cursor = "pointer";

  //         clearInterval(intervalId);
  //       }
  //     }, 100);

  //     return () => clearInterval(intervalId);
  //   }, []);

  return <div className="gcse-search"></div>;
};

export default GoogleCSE;
