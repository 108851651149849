// Sidebar.js
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import "./mobile-nav-header.css";
import GoogleCSE from "./GoogleCSE";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [isMainOpen3, setMainOpen3] = useState(false);

  const toggleMainDropdown3 = () => {
    setMainOpen3(!isMainOpen3);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    setActiveLink(pathname);
  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setMainOpen3(false); // Close the dropdown when a link is clicked
  };

  return (
    <div className="sidenav">
      <Link
        to="/about/"
        className={`dropdown-btn-mobile ${
          activeLink === "/about/" ? "active" : ""
        }`}
        onClick={() => handleLinkClick("/about/")}
      >
        ABOUT
      </Link>
      <Link
        to="/initiatives/"
        className={`dropdown-btn-mobile ${
          activeLink === "/initiatives/" ? "active" : ""
        }`}
        onClick={() => handleLinkClick("/initiatives/")}
      >
        INITIATIVES
      </Link>

      <div style={{ position: "relative" }}>
        <Link
          to="/products/"
          className={`dropdown-btn-mobile ${
            activeLink === "/products/" || activeLink === "/product-yarn/"
              ? "active"
              : "" || activeLink === "/products-filament/"
              ? "active"
              : "" || activeLink === "/pet-recycling/"
              ? "active"
              : ""
          }`}
          onClick={() => handleLinkClick("/products/")}
        >
          PRODUCTS
        </Link>
        <div
          style={{
            color: isMainOpen3 ? "#5d5d5d" : "#fff",
            zIndex: "1",
            marginTop: "-55px",
            right: 0,
            position: "absolute",
          }}
        >
          <button
            style={{
              padding: "20px",
              paddingLeft: "150px",
              paddingRight: "50px",
              backgroundColor: "#01683600",
              border: "none",
              cursor: "pointer",
            }}
            onClick={toggleMainDropdown3}
          >
            &#9660;
          </button>
        </div>
      </div>

      {isMainOpen3 && (
        <div className="dropdown-container-mobile-sub">
          <Link
            to="/product-yarn/"
            className={`dropdown-btn-mobile ${
              activeLink === "/product-yarn/" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("/product-yarn/")}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&#187;&nbsp;&nbsp;YARN
          </Link>
          <Link
            to="/products-filament/"
            className={`dropdown-btn-mobile ${
              activeLink === "/products-filament/" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("/products-filament/")}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&#187;&nbsp;&nbsp;MONOFILAMENTS
          </Link>
          <Link
            to="/pet-recycling/"
            className={`dropdown-btn-mobile ${
              activeLink === "/pet-recycling/" ? "active" : ""
            }`}
            onClick={() => handleLinkClick("/pet-recycling/")}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&#187;&nbsp;&nbsp;PET RECYCLING
          </Link>
        </div>
      )}

      {/* <Link
        to="/faq-page/"
        className={`dropdown-btn-mobile ${
          activeLink === "/faq-page/" ? "active" : ""
        }`}
        onClick={() => handleLinkClick("/faq-page/")}
      >
        FAQ
      </Link> */}
      <Link
        to="/contact-us/"
        className={`dropdown-btn-mobile ${
          activeLink === "/contact-us/" ? "active" : ""
        }`}
        onClick={() => handleLinkClick("/contact-us/")}
      >
        CONTACT
      </Link>
      <div className="parent-container">
        <div
          className="seach-bar"
          style={{ width: "100%", display: "block", border: "none" }}
        >
          <center>
            <GoogleCSE />
          </center>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
